import React from 'react';
import '../Css/App.css'; // CSS dosyanız

const Loading = () => (
  <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
    <img src={`${process.env.PUBLIC_URL}/logoSC.png`} className="loading-logo" alt="Loading..." />
  </div>
);

export default Loading;
