import React from 'react';
import { AppBar, Toolbar, Button, Box } from '@mui/material';
import { Link } from 'react-router-dom';

const Header = ({ showHeader }) => {
  return (
    <AppBar 
      position="fixed" 
      style={{ 
        transition: 'top 0.3s', 
        // top: showHeader ? '0' : '-64px',
        background: 'linear-gradient(to right, #f5f5f5, #009acd)', 
        color: 'white', 
        height: '64px',
        fontWeight: 'bold',
      }}
    >
      <Toolbar style={{ minHeight: '64px', padding: '0' }}>
        <Box display="flex" flexDirection="column" alignItems="center" width="100%">
          
          {/* Scrolling Text */}
          {/* <Box 
            sx={{
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              width: '100%',
              position: 'relative',
              display: 'flex',
              alignItems: 'center',
              height: '32px', // Adjusted height
              fontWeight: 'bold',
              fontSize: { xs: '0.8rem', sm: '1rem' }, // Responsive font size
              color: 'white',
              backgroundColor: 'red',
              padding: '0 4px',
            }}
          >
            <Box
              component="span"
              sx={{
                display: 'inline-block',
                animation: 'scroll 10s linear infinite',
                paddingRight: '100%',
              }}
            >
              "🇹🇷Cumhuriyetimiz 101 yaşında. Yaşasın Cumhuriyet, Yaşasın Tam Bağımsız Türkiye Cumhuriyeti...🇹🇷"
            </Box>
          </Box> */}

          {/* Link Buttons with matching background */}
          <Box 
            display="flex" 
            justifyContent="center" 
            flexWrap="wrap" 
            width="100%" 
            sx={{ 
              zIndex: 2,
              background: 'linear-gradient(to right, #f5f5f5, 009acd)', 
              padding: '4px 0', // Padding for button spacing
            }}
          >
            {['UNIVERSITE', 'LGS', 'KPSS', 'DGS', 'ALES', 'YDS', 'UNOH'].map((text) => (
              <Button
                key={text}
                color="inherit"
                component={Link}
                sx={{ 
                  fontWeight: 'bold', 
                  fontSize: { xs: '0.7rem', sm: '0.9rem' }, // Responsive font size
                  padding: '4px 8px',
                  minWidth: '70px', // Minimum button width
                  margin: '0 2px' // Margin for spacing between buttons
                }} 
                to={`/${text}`}
              >
                {text}
              </Button>
            ))}
          </Box>
        </Box>
      </Toolbar>

      {/* CSS for scrolling animation */}
      <style>
        {`
          @keyframes scroll {
            0% { transform: translateX(100%); }
            100% { transform: translateX(-100%); }
          }
        `}
      </style>
    </AppBar>
  );
};

export default Header;
